






















































import Component, {mixins} from 'vue-class-component';
import DataFormat from '@/shared/utils/dataFormat';
import {Prop} from 'vue-property-decorator';
import { EventBus } from '@/config/config';

@Component
export default class ReviewList extends mixins(DataFormat) {
  @Prop({
    required: true
  })
  public reviews!: any;
  public fetchReview: any = {};

  public thumbnailDetail(index, reviewIndex) {
    EventBus.$emit('thumbnailIndex', index);
    this.fetchReview = this.reviews[reviewIndex];
    this.$emit('fetchReview', this.fetchReview);
  }

  public pastScore(item) {
    if (item.food_stars && item.service_stars && item.mood_stars) return false;
    else return true;
  }

  public starScoreAvg(f, s, m) {
    const score = (f / 20) + (s / 20) + (m / 20);
    return (score / 3).toFixed(1);
  }
}
