





















import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';
import ReviewList from '@/components/list/ReviewList.vue';
import ThumbnailDetailModal from '@/pages/modal/ThumbnailDetailModal.vue';
import ThumbnailListModal from '@/pages/modal/ThumbnailListModal.vue';
import { reviewModule } from '@/store';
import ListMixins, { InfiniteHandlerState } from '@/components/list/ListMixins';
import { EventBus } from '@/config/config';

@Component({
  components: { ReviewList, ThumbnailListModal, ThumbnailDetailModal }
})
export default class PlaceReviewList extends mixins(ListMixins) {
  public params = {
    offset: 1,
    limit: 20,
    type: 'realtime',
    time: this.$moment().format('YYYY-MM-DD HH:mm:ss'),
  }
  public loadingStatus: boolean = false;
  public fetchReview: any = {};

  get reviewList() {
    return reviewModule.userReview;
  }

  get reviewMeta(): any {
    return reviewModule.userReviewMeta;
  }

  public beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.loadingStart();
      vm.params.place_id = to.params.placeId;
      reviewModule.fetchUserReview(vm.params).then(() => {
        vm.loadingEnd();
      });
    })
  }
  public beforeDestroy() {
    this.loadingEnd();
  }
  public loadingStart() {
    EventBus.$emit('loadingStatus', true);
    this.loadingStatus = true;
  }
  public loadingEnd() {
    EventBus.$emit('loadingStatus', false);
    this.loadingStatus = false;
  }

  public thumbnailReview(data) {
    this.fetchReview = data;
    this.$modal.show('thumbnailDetail');
  }

  public $_listMixins_fetch($state: InfiniteHandlerState) {
    reviewModule
      .fetchUserReview(this.params)
      .then(() => {
        if (this.reviewList && this.reviewList.length >= (this.reviewList ? this.reviewMeta.count : 0)) {
          $state.complete();
        } else {
          $state.loaded();
        }
      })
      .finally(() => {
        this.isLoading = false;
      });
  }
}
